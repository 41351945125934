import { AxiosError } from 'axios'

export function switchIn(
    key: string,
    defaultOption: string,
    otherOptions: Record<string, string>
) {
    if (otherOptions.hasOwnProperty(key)) {
        return otherOptions[key]
    }

    return defaultOption
}

export function switchInError(
    error: string | AxiosError,
    messages: Record<string, any>
) {
    if (typeof error === 'string' && messages.hasOwnProperty(error)) {
        return messages[error]
    }

    const errorKey = (error as AxiosError)?.response?.data?.message
    if (errorKey && messages.hasOwnProperty(errorKey)) {
        return messages[errorKey]
    }

    return 'Something went wrong. If the problem continues please contact us.'
}
