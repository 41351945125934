import * as React from 'react'
import { ErrorText, Form, Input, Label } from 'lib/forms'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'
import api from 'lib/api'
import { signIn } from 'next-auth/react'
import Router, { useRouter } from 'next/router'

import { Button, useApp } from 'lib/shared-ui'
import { logEvent, setUserId, useLogOnRender } from '../../lib/analytics'
import { AxiosError } from 'axios'
import config from 'config'
import { switchInError } from '../../lib/switch-in'
import { AuthBody, AuthHeader, AuthLayout } from 'lib/layouts/auth'
import Link from 'next/link'

interface FormValues {
    role: 'customer' | 'employer'
    email: string
    firstName: string
    lastName: string
    companyName: string
    password: string
    phone: string
}

export default function SignUpPage() {
    useLogOnRender('auth:signup-page:view')
    const router = useRouter()
    const [isLoading, setIsLoading] = React.useState(false)
    const { email, firstName, lastName } = router.query

    const methods = useForm<FormValues>({
        defaultValues: {
            role: 'customer',
            email: email as string,
            firstName: firstName as string,
            lastName: lastName as string,
            phone: '',
            password: '',
        },
    })

    const mutation = useMutation(
        (data: Record<string, any>) => {
            return api.post('auth/sign-up', { ...data, role: 'customer' })
        },
        {
            onError: (data: AxiosError) => {
                setIsLoading(false)
                toast.error(
                    switchInError(data, {
                        USER_ALREADY_EXISTS:
                            'User with this email address already exists. Please sign in or try to reset your password.',
                    })
                )
            },
            onSuccess: async (res, data) => {
                logEvent('auth:signed-up', {
                    userId: data.id,
                    userEmail: data.email,
                    userRole: data.role,
                })

                setUserId(data.email)

                await signIn('credentials', {
                    email: data.email,
                    password: data.password,
                    mode: 'password',
                    redirect: false,
                })

                Router.push('/auth/qualify')
            },
        }
    )

    return (
        <AuthLayout id="signup-form">
            <AuthHeader
                title="Create your account"
                // alternative="sign in to your account"
                // alternativePath="/auth/signin"
            />
            <AuthBody>
                <Form
                    onSubmit={(data) => {
                        setIsLoading(true)
                        mutation.mutate(data)
                    }}
                    // @ts-ignore
                    methods={methods}
                >
                    <GoogleButton divider="or signup with email" />

                    <div className="flex flex-row mb-4">
                        <div className="w-1/2 sm:pr-4 pr-2">
                            <Label id="firstName">First Name</Label>
                            <Input
                                id="firstName"
                                type="text"
                                className="mt-1"
                                rules={{ required: true }}
                            />
                            <ErrorText id="firstName">
                                Please enter your first name
                            </ErrorText>
                        </div>

                        <div className="w-1/2 sm:pl-4 pl-2">
                            <Label id="lastName">Last Name</Label>
                            <Input
                                id="lastName"
                                type="text"
                                className="mt-1"
                                rules={{ required: true }}
                            />
                            <ErrorText id="lastName">
                                Please enter your last name
                            </ErrorText>
                        </div>
                    </div>

                    <div className="mb-4">
                        <Label id="email">Email</Label>
                        <Input
                            id="email"
                            type="email"
                            className="mt-1"
                            rules={{ required: true }}
                        />
                        <ErrorText id="email">
                            Please enter a valid email address
                        </ErrorText>
                    </div>

                    <div className="my-4">
                        <Label id="password">Password</Label>
                        <Input
                            id="password"
                            type="password"
                            className="mt-1"
                            rules={{
                                required: true,
                                minLength: 8,
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
                            }}
                        />
                    </div>

                    <ErrorText id="password">
                        Please enter a password that has a minimum 8 characters
                        (min 1 uppercase letter, 1 lowercase and one number).
                    </ErrorText>

                    <div className="flex items-center mt-3">
                        <input
                            id="remember-me"
                            name="remember-me"
                            type="checkbox"
                            required
                            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        />
                        <label
                            htmlFor="remember-me"
                            className="ml-2 block text-sm text-gray-900"
                        >
                            I agree with{' '}
                            <a
                                href={config.legal.tos}
                                className="text-blue-700"
                            >
                                terms
                            </a>{' '}
                            and{' '}
                            <a
                                href={config.legal.privacy}
                                className="text-blue-700"
                            >
                                privacy policy
                            </a>
                        </label>
                    </div>

                    <Button
                        variant="primary"
                        className="w-full mt-5"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Sending ...' : 'Continue →'}
                    </Button>
                </Form>
            </AuthBody>
            <div className="w-full text-center mt-3 text-sm text-gray-700">
                Already have an account?{' '}
                <Link href="/auth/signin" passHref>
                    <a className="font-medium hover:text-indigo-500">
                        sign in here
                    </a>
                </Link>
            </div>
        </AuthLayout>
    )
}

export function GoogleButton({ divider }: { divider: string }) {
    const app = useApp()
    if (app.platform !== 'web') return null

    return (
        <span id="google-auth">
            <Button
                type="button"
                variant="secondary"
                className="w-full flex items-center bg-white border border-gray-300 rounded-lg shadow-md px-6 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                onClick={() => signIn('google')}
            >
                <svg
                    className="h-6 w-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="800px"
                    height="800px"
                    viewBox="-0.5 0 48 48"
                    version="1.1"
                >
                    <title>Google-color</title>
                    <defs />
                    <g
                        id="Icons"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                    >
                        <g
                            id="Color-"
                            transform="translate(-401.000000, -860.000000)"
                        >
                            <g
                                id="Google"
                                transform="translate(401.000000, 860.000000)"
                            >
                                <path
                                    d="M9.82727273,24 C9.82727273,22.4757333 10.0804318,21.0144 10.5322727,19.6437333 L2.62345455,13.6042667 C1.08206818,16.7338667 0.213636364,20.2602667 0.213636364,24 C0.213636364,27.7365333 1.081,31.2608 2.62025,34.3882667 L10.5247955,28.3370667 C10.0772273,26.9728 9.82727273,25.5168 9.82727273,24"
                                    id="Fill-1"
                                    fill="#FBBC05"
                                />
                                <path
                                    d="M23.7136364,10.1333333 C27.025,10.1333333 30.0159091,11.3066667 32.3659091,13.2266667 L39.2022727,6.4 C35.0363636,2.77333333 29.6954545,0.533333333 23.7136364,0.533333333 C14.4268636,0.533333333 6.44540909,5.84426667 2.62345455,13.6042667 L10.5322727,19.6437333 C12.3545909,14.112 17.5491591,10.1333333 23.7136364,10.1333333"
                                    id="Fill-2"
                                    fill="#EB4335"
                                />
                                <path
                                    d="M23.7136364,37.8666667 C17.5491591,37.8666667 12.3545909,33.888 10.5322727,28.3562667 L2.62345455,34.3946667 C6.44540909,42.1557333 14.4268636,47.4666667 23.7136364,47.4666667 C29.4455,47.4666667 34.9177955,45.4314667 39.0249545,41.6181333 L31.5177727,35.8144 C29.3995682,37.1488 26.7323182,37.8666667 23.7136364,37.8666667"
                                    id="Fill-3"
                                    fill="#34A853"
                                />
                                <path
                                    d="M46.1454545,24 C46.1454545,22.6133333 45.9318182,21.12 45.6113636,19.7333333 L23.7136364,19.7333333 L23.7136364,28.8 L36.3181818,28.8 C35.6879545,31.8912 33.9724545,34.2677333 31.5177727,35.8144 L39.0249545,41.6181333 C43.3393409,37.6138667 46.1454545,31.6490667 46.1454545,24"
                                    id="Fill-4"
                                    fill="#4285F4"
                                />
                            </g>
                        </g>
                    </g>
                </svg>

                <span>Continue with Google</span>
            </Button>

            <div className="relative mt-6 mb-6">
                <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                >
                    <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-white px-6 text-gray-900">
                        {divider}
                    </span>
                </div>
            </div>
        </span>
    )
}
